import React, { useEffect, useState } from 'react'

export function Footer() {
    const d = new Date();
    let year = d.getFullYear();

    return (
        <footer className="py-5 bg-black">
            <div className="container px-5"><p className="m-0 text-center text-white small">Copyright &copy; STEM ROBOCLUB {year}</p></div>
        </footer>
    )
}
