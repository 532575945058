import React, { useEffect, useState } from 'react'
import './css/content.css'

export function Content(props: any) {
    if (props.indent == 'left') {
        return (
            <section id="scroll">
                <hr />
                <div className="container px-1 px-lg-5">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-6 col-12 order-lg-2">
                            <div className="p-1 p-lg-5"><img className="img img-fluid w-100 rounded" src={props.image} alt="..." /></div>
                        </div>
                        <div className="col-lg-6 col-12 order-lg-1">
                            <div className="p-1 p-lg-5">
                                <h2 className="display-4">{props.heading}</h2>
                                {props.age && <h2 className="display-4 fs-2">{props.age}</h2>}
                                <p>{props.details}</p>
                                <p>SHARE :</p>
                                    <div className="container px-4 px-lg-5">
                                        <ul className="list-inline mb-4">
                                            <li className="list-inline-item">
                                                <a className="social-link rounded-circle text-white mr-3" href={props.fb_link} target="_blank" rel="noopener noreferrer"><i className="icon-social-facebook"></i></a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a className="social-link rounded-circle text-white mr-3" href={props.tw_link} target="_blank" rel="noopener noreferrer"><i className="icon-social-twitter"></i></a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a className="social-link rounded-circle text-white" href={props.in_link} target="_blank" rel="noopener noreferrer"><i className="icon-social-linkedin"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                {/* <a className="btn btn-primary btn-md" href="#services">Details...</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    else {
        return (
            <section>
                <hr />
                <div className="container px-1 px-5">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="p-1 p-lg-5"><img className="img img-fluid w-100 rounded" src={props.image} alt="..." /></div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="p-1 p-lg-5">
                                <h2 className="display-4">{props.heading}</h2>
                                {props.age && <h2 className="display-4 fs-2">{props.age}</h2>}
                                <p>{props.details}</p>
                                <p>SHARE :</p>
                                    <div className="container px-4 px-lg-5">
                                    <ul className="list-inline mb-4">
                                            <li className="list-inline-item">
                                                <a className="social-link rounded-circle text-white mr-3" href={props.fb_link} target="_blank" rel="noopener noreferrer"><i className="icon-social-facebook"></i></a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a className="social-link rounded-circle text-white mr-3" href={props.tw_link} target="_blank" rel="noopener noreferrer"><i className="icon-social-twitter"></i></a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a className="social-link rounded-circle text-white" href={props.in_link} target="_blank" rel="noopener noreferrer"><i className="icon-social-linkedin"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                {/* <a className="btn btn-primary btn-md" href="#services">Details...</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
