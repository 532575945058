import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function App() {
  return (
    <div className="App">
      <Suspense fallback={"Loading...."}>
        <Outlet />
      </Suspense>
      {/* <TawkMessengerReact
                propertyId="64b59a7acc26a871b028fdc0"
                widgetId="1h5im6s2v"/> */}
    </div>
  );
}

export default App;
