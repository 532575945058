import React, { useEffect, useState } from 'react'
import { Content } from './content'


export function Upcoming() {
    return (
        <section className="content-section text-center text-light" id="services" style={{
            background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.8) 60%, rgba(0, 0, 0, 0.4) 100%), url("/media/imgs/courses-min.jpeg")',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
            }}>
        <div className="container px-1 px-lg-5">
            <div className="content-section-heading">
                <h3 className="text-secondary mb-0">Services</h3>
                <h2 className="mb-5">Our Upcomming Programs</h2>
                <Content
                    indent='left'
                    image='/media/imgs/upcoming/1.jpg'
                    heading='1-On-1 Online Session'
                    details="Your own Online live session - where you are the only student and your teacher's time is 100% yours."
                    fb_link='https://www.facebook.com/sharer/sharer.php?u=https://www.stemroboclub.com/event-details/1-on-1-online-session&quote=Check%20out%20this%20event.%20Hope%20to%20see%20you%20there!'
                    tw_link='https://twitter.com/intent/tweet?url=https://www.stemroboclub.com/event-details/1-on-1-online-session&text=Check%20out%20this%20event.%20Hope%20to%20see%20you%20there!'
                    in_link='https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fwww.stemroboclub.com%2Fevent-details%2F1-on-1-online-session'
                />
                <Content
                    indent='right'
                    image='/media/imgs/upcoming/2.jpg'
                    heading='Python Programming for Beginners '
                    age= '(Age: 10+)'
                    details="Your own Online live session - where you are the only student and your teacher's time is 100% yours."
                    fb_link='https://www.facebook.com/sharer/sharer.php?u=https://www.stemroboclub.com/event-details/python-programming-for-beginners-age-10&quote=Check%20out%20this%20event.%20Hope%20to%20see%20you%20there!'
                    tw_link='https://twitter.com/intent/tweet?url=https://www.stemroboclub.com/event-details/python-programming-for-beginners-age-10&text=Check%20out%20this%20event.%20Hope%20to%20see%20you%20there!'
                    in_link='https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fwww.stemroboclub.com%2Fevent-details%2Fpython-programming-for-beginners-age-10'
                />
                <Content
                    indent='left'
                    image='/media/imgs/upcoming/3.jpg'
                    heading='Mind Maths - Using Fingers and Abacus '
                    age='(Age: 7-10)'
                    details="Your own Online live session - where you are the only student and your teacher's time is 100% yours."
                    fb_link='https://www.facebook.com/sharer/sharer.php?u=https://www.stemroboclub.com/event-details/mind-maths-for-kids-age-7-12&quote=Check%20out%20this%20event.%20Hope%20to%20see%20you%20there!'
                    tw_link='https://twitter.com/intent/tweet?url=https://www.stemroboclub.com/event-details/mind-maths-for-kids-age-7-12&text=Check%20out%20this%20event.%20Hope%20to%20see%20you%20there!'
                    in_link='https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fwww.stemroboclub.com%2Fevent-details%2Fmind-maths-for-kids-age-7-12'
                />
            </div>
        </div>
    </section>
    )
}
