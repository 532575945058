import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import { Homepage } from '../pages/homepage'
import { CoursesPage } from '../pages/courses'

const PublicRoutes = () => {

  return (
    <Routes>
      <Route
          path='/courses'
          element={
            <CoursesPage />
          }
        />
      <Route
          path='/'
          element={
            <Homepage/>
          }
        />
      <Route
          path='/home'
          element={
            <Homepage/>
          }
        />
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}

export {PublicRoutes}
