export const detailsObj = [
    {
        name : 'Smart Studio',
        subname: 'Unleash Your Robotic Genius!',
        age : '10-17 yrs',
        hours: '32 x 1 hour sessions',
        cost: 'RS 5,500/month',
        session: 'Online Session',
        description: [
            `Welcome to the Smart Studio, an electrifying journey into the fascinating world of robotics and automation! In this dynamic course, students will dive headfirst into the realm of ARDUINO micro-controller, DIY electronics parts, and the art of programming to create intelligent and responsive devices with endless possibilities and applications. Whether it's crafting innovative projects for your school science fair or inventing something truly useful, this course will equip you with the skills and knowledge to excel.`,
            `Throughout this comprehensive program, participants will progress through three engaging levels of learning, carefully designed to cater to every child's unique learning curve and foster a deep understanding of STEM concepts. In Level 1, our young innovators will be immersed in a captivating world of discovery as they explore the basics of electronics circuit building. Guided by our expert instructors, they will learn to wield the power of micro-controllers and seamlessly integrate sensors, unraveling the secrets of technology's building blocks. Through hands-on projects and interactive sessions, they will gain the confidence to harness their creativity and make devices respond intelligently with the magic of scratch coding.`,
            `Building on their foundation of knowledge, Level 2 opens the gateway to an awe-inspiring realm of automation. Students will delve into the realm of digital sensors, understanding their role in shaping the future of smart technologies. As they tinker with these cutting-edge components, they will witness firsthand how automation simplifies tasks and enhances efficiency. Encouraged to think critically and problem-solve, they will engineer their own ingenious automation projects, elevating their skills to new heights.`,
            `Finally, in Level 3, learners will ascend to the pinnacle of their transformative journey, where they become the masters of their creations. Guided by their acquired expertise and nurtured talents, they will embark on the exhilarating challenge of crafting their very own smart robotic car. This culmination of the program is an epitome of innovation and achievement, showcasing their ingenuity in designing a fully-functional, autonomous vehicle. Under the mentorship of our experienced faculty, they will explore the intricacies of robotics and automation, taking their passion for STEM to unexplored horizons.`,
            `With each level building upon the last, our mission is to instill not just knowledge, but also the art of critical thinking, problem-solving, and collaborative teamwork. Our students will leave the Smart Studio with a deep sense of accomplishment, equipped with a toolkit of skills that will propel them towards future success in academia and beyond. So, join us in this extraordinary journey of creativity, discovery, and learning, and let the Smart Studio unlock your child's boundless potential in the captivating world of STEM.`
        ],
        duration: '(7-months, 2 sessions/week)',
        fee: {
            group : 'Rs 5,500/month (*including Kit and courier charges (Pakistan)',
            one2one: 'Rs 15,000/month (*including Kit and courier charges (Pakistan)'
        },
        form: 'https://forms.gle/BySLXK7AKqProRLg8'
    },
    {
        name : 'Mind Maths',
        subname: 'Beginners',
        age : '7+',
        hours: '24 hr',
        cost: 'RS 3000/month',
        session: 'Online Session',
        description: [
            `Mental math using the Japanese technique on fingers & ABACUS. Improve listening, memory, processing speed and attention skills with quick counting.`,
            `The abacus, which the Japanese call soroban, is an ancient device made up of parallel rods, each containing five beads each. It is still used in Japan and other Asian countries to teach students how to perform addition, subtraction, multiplication, division and solve Arithmetic problems. Despite the advent of calculators in digital devices, it remains popular because by exercising the brain regularly under guided instructions, it helps develop mental skills that are still relevant, such as concentration and memorization.`,
            `As a result, children have sharper, more alert minds, and this helps them not only in Mathematics but also in other subjects. They are also more confident to perform well in their secondary school math exams and in university entrance and aptitude tests.`,
            `Eventually, kids are able to perform mental math calculations by using a virtual or imaginary abacus, without needing a physical abacus. Detailed work sheets have been developed utilizing this method and sessions are conducted by trained instructors.`,
            `It has total 3 levels, each of duration 24 x 1-hour sessions. In Level 1, kids will be learn to do addition and subtraction up to 2-digit numbers on fingers and abacus.`,
            `Students will complete addition/ subtraction of any number (1000's+) in Level 2. Level 3 is based on multiplication and division.`
        ],
        duration: '',
        fee: {
            group : 'Rs 3000/month (2 sessions/week)',
            one2one: 'Rs 10,000/month (2 sessions/week)'
        },
        form: 'https://forms.gle/AGJCLwVf2nr8fVaM7'
    },
    {
        name : 'Graphics Studio',
        subname: 'Adobe Illustrator',
        age : '9+',
        hours: '15 hr',
        cost: 'RS 3500',
        session: 'Online Session',
        description: [
            `Adobe Illustrator is a professional vector-based design and drawing program. Used as part of a larger design workflow, illustrator allows for the creation of everything from single design elements to entire compositions. Designers use Illustrator to create posters, symbols, logos, patterns, icons, Birthday Card, cartoon characters etc.`
        ],
        duration: '',
        fee: null,
        form: 'https://forms.gle/AGJCLwVf2nr8fVaM7'
    },
    {
        name : 'GAME STUDIO',
        subname: 'Beginners',
        age : '8+',
        hours: '15 hr',
        cost: 'RS 3000',
        session: 'Online Session',
        description: [
            `What if instead of just playing games and watching TV, your kids could make THEIR OWN games?`,
            `This course is aimed for kids between 8 and 16 and they'll learn how to make simple games on Construct-2.`,
            `Construct-2 makes it very simple for kids to understand basic coding concepts and to make their creations come true by just dragging and dropping elements and connecting them with each other. It is highly visual, interactive, and fun!`,
            `What your kids will learn in this course:`,
            `Using intuitive building blocks to visually craft simple web games, stories, and animations. Grasping programming concepts such as conditions and loops. Taught with a project-based methodology.`,
            `Make their creations, interactive for the users. Most importantly, understand how simple it is to make their creative ideas a reality in today's world.`,
        ],
        duration: '',
        fee: null,
        form: 'https://forms.gle/AGJCLwVf2nr8fVaM7'
    },
    {
        name : 'APP STUDIO',
        subname: 'Android',
        age : '9+',
        hours: '30 hr',
        cost: 'RS 6000',
        session: 'Online Session',
        description: [
            `Interested in how to make Android applications?`,
            `This android development training course is designed to quickly get you up to speed how to make applications for Android devices. It will teach you the basis of the application designing by using MIT App Inventor.`,
            `You will be able to use built in widgets and components, create AI based intelligent applications, work with the database to store data on a device, program them using block programming and have fun by sharing and using them on smartphones and much more by the end of this course.`
        ],
        duration: '',
        fee: null,
        form: 'https://forms.gle/AGJCLwVf2nr8fVaM7'
    },
    {
        name : 'C++ Programing',
        subname: 'Programing Language',
        age : '10+',
        hours: '24 hr',
        cost: 'RS 6000',
        session: 'Online Session',
        description: [
            `Learn C++ Programming Language.`,
        ],
        duration: '',
        fee: null,
        form: 'https://forms.gle/AGJCLwVf2nr8fVaM7'
    },
    {
        name : 'Python',
        subname : 'Programing Language',
        age : '10+',
        hours: '32 hr',
        cost: 'RS 3000/month',
        session: 'Online Session',
        description: [
            `This course is designed to help students and programming enthusiasts who are just beginning to learn programming. The goal is to help learners get a better grasp of the concepts of programming, along with a little bit of fun.`,
            `Learning to code is learning to think creatively. If you can code a computer, then you can think and apply yourself logically and rationally to tackle any obstacle or challenge you face in life. It is a wonderful life skill for your child to build.`,
            `Python is an extremely flexible coding language that is used by many professionals, including scientists, researchers, and engineers. It is straightforward and approachable and an excellent choice for children beginning their coding journey.`,
        ],
        duration: '',
        fee: {
            group : 'Rs 3000/month (2 sessions/week)',
            one2one: 'Rs 10,000/month (2 sessions/week)'
        },
        form: 'https://forms.gle/Qxr3tnYUDLZoie9u7'
    },
    {
        name : 'SMART ROBOTICS',
        age : '9+',
        hours: '36 hr',
        cost: 'RS 9000',
        session: 'Online Session',
        description: [
            `Learn to use 'Arduino micro-controller', DIY electronics parts and make your own "Line follower, Hurdle detector and Smartphone controlled Robotic car".`,
        ],
        duration: '',
        fee: null,
        form: ''
    },
    {
        name : 'MS Office',
        subname: 'Beginners',
        age : '7+',
        hours: '15 hr',
        cost: 'RS 3000',
        session: 'Online Session',
        description: [
            `This course is for learners who may or may not have used the Office suite but who wish to achieve general Office proficiency. We will be covering the three most common Office programs: Word, Excel, and PowerPoint`,
            `Microsoft Office skills such as Power Point to create presentation slides, Word to create document and reports and Excel to plot charts and process data would benefit children during their school years and in just about any profession they may choose as adults.`,
        ],
        duration: '',
        fee: null,
        form: ''
    },
     {
        name : 'WEB DESIGNING',
        subname: 'Beginners',
        age : '9+',
        hours: '15 hr',
        cost: 'RS 3000',
        session: 'Online Session',
        description: [
            `Do your kids spend too much time on the computer, phone, or tablet? Don't you wish they could do something more productive? Why not get them to learn a lifelong valuable skill.`,
            `Get ready to build your very first website with HTML and CSS.`,
            `This is the perfect course to start with. This course is designed for beginners that are ready to learn HTML and CSS without any coding experience required.`
        ],
        duration: '',
        fee: null,
        form: ''
    },

]
