import React, { useEffect, useState } from 'react'

export function Course(props: any) {
    const [hover, setHover] = useState(false)

    return (
        <>
            <div className="col-lg-4 mt-3" onClick={()=>props.setDetailsId(props.courseId)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                <a className="portfolio-item mx-3" href="#!" >
                    <div className="caption">
                        <div className="caption-content text-center">
                            <h1 className='caption-heading mb-0 mt-1'>{props.heading}</h1>
                            <p className='mb-4 text-secondary fw-bold'>{props.subheading}</p>
                            <p className="mb-1 content-age">AGE: {props.age}</p>
                            <p className="mb-1">TIME: {props.time}</p>
                            {!hover ? (
                                <>
                                    <p className="mb-1">...</p>
                                </>
                            ) : (
                                <>

                                    <p className="mb-1 text-secondary content-age">Click To See Details</p>
                                </>
                            )}
                        </div>
                    </div>
                    <img className="img-fluid img" src={props.img} style={{
                        width: '100%',
                        height: '250px',
                        objectFit: 'cover'
                    }} alt="..." />
                </a>
            </div>

        </>

    )
}
