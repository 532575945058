import React, { useEffect, useState } from 'react'
import './css/navbar2.css'

export function Navbar(props: any) {
  const [type, setType] = useState(props.isActive)

  return (
    <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top py-1">
      <div className="container px-5">
        <a className="navbar-brand d-none d-lg-block " href="#page-top">
          <img src="/logo-new.png" width='30%' className="img img-fluid rounded bg-light py-1 px-3"  alt="" />
        </a>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <div className="row">
          <div className="col-1 my-auto">
              <span className="navbar-toggler-icon">
              </span>
              </div>
          <div className="col-11">
            <a className="navbar-brand text-center d-block d-lg-none" href="#page-top">
              <img src="/logo-new.png" className="img img-fluid rounded w-50 bg-light py-1 px-3" alt="" />
            </a>
          </div>
        </div>

            </button>


        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item"><a className={"nav-link " + (type == 'homepage' ? "active" : "")} onClick={() => setType('homepage')} href="/">Home</a></li>
            <li className="nav-item"><a className={"nav-link " + (type == 'courses' ? "active" : "")} href="/courses">Courses</a></li>
            <li className="nav-item"><a className={"nav-link " + (type == 'contact' ? "active" : "")} onClick={() => setType('contact')} href="#contact">Contact</a></li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
