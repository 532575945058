import React from 'react'
import { Navbar } from './components/navbar2'
import { BigScreen } from './components/bigscreen'
import { Callout } from './components/callout'
import { Upcoming } from './components/upcoming'
import { Quote } from './components/quote'
import { Footer } from './components/footer'
import { Maps } from './components/maps'
import { Contact } from './components/contact'

export function Homepage() {
    return (
        <>
            <Navbar isActive='homepage'/>
            <BigScreen />
            <Callout />
            <Upcoming />
            <Quote />
            <Contact />
            <Footer />
        </>

    )
}
