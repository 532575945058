import React, { useEffect, useState } from 'react'
import './css/callout.css'
import './css/quote.css'

export function Callout() {
    return (
        <section className="quote text-white callout">
            <div className="row  quote-content mx-auto px-3 px-lg-5">
                <div className="col-md-6 col-12">
                    <div className="container px-1 px-lg-5 text-center">
                        <h2 className="display-4 mx-auto mb-5">OUR STORY</h2>
                        <p className="lead mb-5" style={{
                            textAlign: "justify",
                            textJustify: "inter-word"
                        }}>
                            Welcome to our premier online STEM education institute, where the wonders of science, technology, engineering, and mathematics come to life! As pioneers in the realm of modern education, we take great pride in nurturing the curious minds of young learners, preparing them to become the innovators and problem solvers of tomorrow. With a perfect blend of cutting-edge virtual learning and engaging physical classes, we offer an enriching educational experience like no other. Step into a world where imagination knows no bounds and knowledge knows no limits, as we embark on a journey of exploration, discovery, and creativity together. Whether your child dreams of building robots, unraveling the mysteries of the universe, or coding the next big software, our diverse range of online and physical STEM courses are designed to empower every young mind to thrive in a rapidly evolving world. Join us and let the adventure begin!
                        </p>
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="container px-1 px-lg-5 text-center">
                        <h2 className="display-4 mx-auto mb-5">WHAT WE DO?</h2>
                        <p className="lead mb-5" style={{
                            textAlign: "justify",
                            textJustify: "inter-word"
                        }}>
                           At our online STEM education institute, we are dedicated to igniting the spark of curiosity in young minds through a comprehensive array of both virtual and in-person STEM courses designed specifically for kids. Our mission is to cultivate a passion for science, technology, engineering, and mathematics from an early age, nurturing the innovators and problem solvers of tomorrow. With a team of experienced educators and industry experts, we strive to provide a dynamic and engaging learning environment that encourages exploration, critical thinking, and hands-on experimentation. Through our carefully crafted online modules and interactive physical classes, we aim to empower children with the knowledge and skills needed to excel in an ever-evolving technological landscape. From robotics and coding to space exploration and beyond, we unlock the door to endless possibilities, ensuring that each child's journey with us is filled with excitement, inspiration, and the joy of discovery. Together, let's embark on an extraordinary learning adventure that lays the foundation for a brilliant future.
                        </p>
                    </div>
                </div>
            </div>
            <div className="bg-circle-1 bg-circle"></div>
            <div className="bg-circle-2 bg-circle"></div>
            <div className="bg-circle-3 bg-circle"></div>
            <div className="bg-circle-4 bg-circle"></div>
        </section>
    )
}
