import React, { useEffect, useState } from 'react'
import './css/content.css'

export function Detail(props: any) {

        return (
            <section>
                <hr />
                <div className="container px-lg-5 px-1">
                    <div className="row gx-lg-5 gx-1 align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="p-lg-5 p-1"><img className="img img-fluid w-100 rounded" src={props.image} alt="..." /></div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="p-lg-5 p-1">
                                <table className="table my-4">
                                    <thead className='text-white'>
                                        <tr>
                                            <th scope="col">Duration</th>
                                            <td scope="col">{props.detailsObj.hours}</td>
                                        </tr>
                                        {/* <tr>
                                            <th scope="col">Cost</th>
                                            <td scope="col">{props.detailsObj.cost}</td>
                                        </tr> */}
                                        <tr>
                                            <th scope="col">Type</th>
                                            <td scope="col">{props.detailsObj.session}</td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <h2 className="display-4 mb-3">Course Description</h2>
                        {props.detailsObj.description.map((paragraph:any, index:number)=>(
                            <p>{paragraph}</p>
                        ))}
                    </div>
                    <div className="row">
                        {/* {props.detailsObj.duration && (
                            <>
                                <u><h5 className='mb-0 pb-0'>Duration:</h5></u>
                                <p>{props.detailsObj.duration}</p>
                            </>
                        )}

                        {props.detailsObj.fee && (
                            <>
                                <u><h5 className='mb-0 pb-0'>Fee:</h5></u>
                                <p className='my-0'><b>Group Session:</b>&nbsp;&nbsp;&nbsp;{props.detailsObj.fee.group}</p>
                                <p className='mt-0 mb-3'><b>1-on-1 Session:</b>&nbsp;&nbsp;&nbsp;{props.detailsObj.fee.one2one}</p>
                            </>
                        )} */}
                        <>
                            <u><h5 className='mb-0 pb-0'>Questions?</h5></u>
                            <p className='my-0'>Whatsapp: <a href='https://wa.me/923046700004' className='text-white'>+92-304-6700004</a></p>
                            <p className='my-0'>Email:  <a href='mailto:stemroboclub@gmail.com' className='text-white'>stemroboclub@gmail.com</a></p>
                            <p className='mt-0'>Address: 54-B St.13A MACHS Karachi.</p>
                        </>
                        {props.detailsObj.form && (
                            <>
                                {/* <u><h5 className='mb-0 pb-0'>For Registration:</h5></u> */}
                                <a className='btn w-25 mx-auto bg-dark text-white' href={props.detailsObj.form} target="_blank" rel="noopener noreferrer">Register</a>
                            </>
                        )}

                    </div>
                </div>
            </section>
        )
}
