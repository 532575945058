import React, { useEffect, useState } from 'react'
import './css/quote.css'

export function Maps() {
    return (
        <div className="map" id="contact">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d977.3470709902203!2d67.08735306427606!3d24.877371340232703!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33eb6996c52f3%3A0xd3a33ddc20e90b56!2sSTEM%2BROBOCLUB%2C%20Mohammad%20Ali%20Society%20Muhammad%20Ali%20Chs%20(Machs)%2C%20Karachi%2C%20Karachi%20City%2C%20Sindh%2C%20Pakistan!5e0!3m2!1sen!2s!4v1685124955590!5m2!1sen!2s" width="600" height="450" style={{border:0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    )
}
