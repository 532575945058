import React, { useEffect, useState } from 'react'
import './css/quote.css'

export function Quote() {
    return (
        <section className="quote text-center text-white py-5">
        <div className="quote-content py-5">
            <div className="container px-5">
                <h2 className="quote-subheading mb-0">The Secret of Getting Ahead</h2>
                <h1 className="quote-heading mb-0">is Getting Started</h1>
                <q className='fs-2'>Mark Twain</q>
            </div>
        </div>
        <div className="bg-circle-1 bg-circle"></div>
        <div className="bg-circle-2 bg-circle"></div>
        <div className="bg-circle-3 bg-circle"></div>
        <div className="bg-circle-4 bg-circle"></div>
    </section>
    )
}
