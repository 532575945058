import React, { useState } from 'react'
import { Navbar } from './components/navbar2'
import { BigScreen } from './components/bigscreen'
import { Courses } from './components/courses'
import { Footer } from './components/footer'
import { Details } from './components/details'
import { Contact } from './components/contact'

export function CoursesPage() {
    const [detailsId, setDetailsId] = useState(0)

    return (
        <>
            <Navbar isActive='courses'/>
            <BigScreen />
            <Courses setDetailsId={setDetailsId} />
            <Contact />
            <Footer />
            <Details detailsId={detailsId} />
        </>

    )
}
