import React, { useEffect, useState } from 'react'
import './css/contact.css'
import { Maps } from './maps'

export function Contact() {


  return (
    <div className="container py-5" id="contact">
      <div className="row"><h2 className='contact-header'>Contact Us</h2></div>
      <div className="row">
        <div className="col-md-6">
          <form
            className="mb-5"
            method="post"
            id="contactForm"
            name="contactForm"
          >
            <div className="row">
              <div className="col-md-12 form-group">
                <label htmlFor="name" className="col-form-label">
                  Name
                </label>
                <input type="text" className="form-control" name="name" id="name" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-group">
                <label htmlFor="email" className="col-form-label">
                  Email
                </label>
                <input type="text" className="form-control" name="email" id="email"/>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-group">
                <label htmlFor="email" className="col-form-label">
                  Phone
                </label>
                <input type="text" className="form-control" name="email" id="email"/>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-group">
                <label htmlFor="message" className="col-form-label">
                  Message
                </label>
                <textarea
                  className="form-control"
                  name="message"
                  id="message"
                  cols={30}
                  rows={7}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-4">
                <input
                  type="submit"
                  defaultValue="Send Message"
                  className="btn btn-primary rounded-0 py-2 px-4"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-5 mr-auto">
          <Maps />
        </div>
      </div>
    </div>

  )
}
