import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { AppRoutes } from './routes/AppRoutes';
// import { Toaster } from 'react-hot-toast'

// import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <AppRoutes />
    {/* <Toaster position={'top-center'} toastOptions={{ className: 'react-hot-toast' }} /> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
