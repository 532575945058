import React, { useEffect, useState } from 'react'
import './css/callout.css'
// import './css/quote.css'
import './css/courses.css'
import { Course } from './course'
import { detailsObj } from './detailsObj'

export function Courses(props:any) {
    return (
        <section className="quote text-white callout">
            <div className="container quote-content mx-auto px-lg-5 px-1 pb-5">
                <div className="content-section-heading text-center">
                    <h3 className="text-secondary mb-0">Our Offerings</h3>
                    <h2 className="mb-5">STEM COURSES</h2>
                </div>
                <div className="row gx-0">
                    {detailsObj.map((obj:any, index:number)=>{
                        return (
                            <Course img={'media/imgs/stem_courses/'+index+'.jpg'}  setDetailsId={props.setDetailsId} courseId={index} heading={obj.name} subheading={obj.subname} age={obj.age} time={obj.hours} />
                        )
                    })}
                </div>
            </div>
            <div className="bg-circle-1 bg-circle"></div>
            <div className="bg-circle-2 bg-circle"></div>
            <div className="bg-circle-3 bg-circle"></div>
            <div className="bg-circle-4 bg-circle"></div>
        </section>
    )
}
