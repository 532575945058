import React, { useEffect, useState } from 'react'
import './css/bigscreen.css'

export function BigScreen() {
    useEffect(()=>{
        var video = document.querySelector('video');
        if(video){
            video.muted = true;
            video.play()
        }

    }, [])
    return (
        <>
        <header className="masthead d-flex py-0 align-items-center video-container">
            <video muted loop>
                <source src="/media/videos/bigscreen.mp4" type="video/mp4" />
            </video>
            <div className="container px-4 px-lg-5 text-center caption">
                <h1 className="mb-1 text-white custom-shadow" style={{fontFamily:"'Lato', sans-serif"}}>STEM ROBOCLUB</h1>
                <h3 className="mb-5 text-white custom-shadow" style={{fontFamily:"'Lato', sans-serif"}}><em>POLISHING YOUTH TO BECOME INNOVATOR</em></h3>
            </div>
        </header>
        </>
    )
}
