import React, { useEffect, useState } from 'react'
import './css/quote.css'
import { detailsObj } from './detailsObj'
import { Detail } from './detail'

export function Details(props: any) {
    return (
        <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body p-0">
                        <section className="content-section text-center text-light quote pt-5" id="services">
                            <div className="container px-4 px-lg-5 quote-content">
                                <div className="content-section-heading">
                                    <h2 >{detailsObj[props.detailsId].name}</h2>
                                    <h3 className="text-secondary fs-4 mb-0">AGE: {detailsObj[props.detailsId].age} years</h3>

                                    <Detail
                                        image={'media/imgs/stem_courses/'+props.detailsId+'.jpg'}
                                        detailsObj = {detailsObj[props.detailsId]}
                                    />
                                </div>
                            </div>
                            <div className="bg-circle-1 bg-circle"></div>
                            <div className="bg-circle-2 bg-circle"></div>
                            <div className="bg-circle-3 bg-circle"></div>
                            <div className="bg-circle-4 bg-circle"></div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}
